<div [formGroup]="tuboForm" class="grid">
  <div class="field col-12 sm:col-6">
    <b>Material</b>
    <mat-form-field>
      <mat-label>Material</mat-label>
      <mat-select formControlName="material">
        <mat-option *ngFor="let material of materiales" [value]="material">
          {{ material }}
        </mat-option>
      </mat-select>
      <mat-error [fieldShowErrors]="tuboForm.get('material')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Consecutivo de fabrica</b>
    <mat-form-field>
      <mat-label>Consecutivo</mat-label>
      <input
        matInput
        type="number"
        class="no-arrows"
        formControlName="consecutivoFabricante"
      />
      <mat-error
        [fieldShowErrors]="tuboForm.get('consecutivoFabricante')!"
      ></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Colada</b>
    <mat-form-field>
      <mat-label>Colada</mat-label>
      <input matInput formControlName="colada" />
      <mat-error [fieldShowErrors]="tuboForm.get('colada')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Foto del código de la colada</b>
    <app-cam-field formControlName="fotoColada"></app-cam-field>
    <mat-error [fieldShowErrors]="tuboForm.get('fotoColada')!"></mat-error>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Foto de remision</b>
    <app-cam-field formControlName="fotoRemision"></app-cam-field>
    <mat-error [fieldShowErrors]="tuboForm.get('fotoRemision')!"></mat-error>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Longitud</b>
    <mat-form-field>
      <mat-label>Longitud</mat-label>
      <input
        matInput
        formControlName="longitud"
        type="number"
        class="no-arrows"
      />
      <mat-error [fieldShowErrors]="tuboForm.get('longitud')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Diametro</b>
    <mat-form-field>
      <mat-label>Diametro</mat-label>
      <mat-select formControlName="diametro">
        <mat-option *ngFor="let diametro of diametrosList" [value]="diametro">
          {{ diametro }}
        </mat-option>
      </mat-select>
      <mat-error [fieldShowErrors]="tuboForm.get('diametro')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Schedule</b>
    <mat-form-field>
      <mat-label>Schedule</mat-label>
      <mat-select formControlName="schedule">
        <mat-option *ngFor="let schedule of schedulesList" [value]="schedule">{{
          schedule
        }}</mat-option>
      </mat-select>
      <mat-error [fieldShowErrors]="tuboForm.get('schedule')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Proceso de fabricación</b>
    <mat-form-field>
      <mat-label>Proceso</mat-label>
      <mat-select formControlName="proceso">
        <mat-option *ngFor="let proceso of procesos" [value]="proceso">{{
          proceso
        }}</mat-option>
      </mat-select>
      <mat-error [fieldShowErrors]="tuboForm.get('proceso')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Procedencia</b>
    <mat-form-field>
      <mat-label>Procedencia</mat-label>
      <mat-select formControlName="procedencia">
        <mat-option *ngFor="let procedencia of procedenciaList" [value]="procedencia">{{
          procedencia
        }}</mat-option>
      </mat-select>
      <mat-error [fieldShowErrors]="tuboForm.get('procedencia')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Recubrimiento</b>
    <mat-form-field>
      <mat-label>Recubrimiento</mat-label>
      <mat-select formControlName="recubrimiento">
        <mat-option *ngFor="let recubrimiento of recubrimientoList" [value]="recubrimiento">{{
          recubrimiento
        }}</mat-option>
      </mat-select>
      <mat-error [fieldShowErrors]="tuboForm.get('recubrimiento')!"></mat-error>
    </mat-form-field>
  </div>
</div>
