import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, catchError, retry } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class MonitorInterceptor implements HttpInterceptor {
  constructor(private snackbar: MatSnackBar) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry(3),
      catchError((error) => {
        this.snackbar.open('Error en la petición: ' + error.message, 'Cerrar', {
          duration: 5000,
        });
        throw error;
      })
    );
  }
}
