import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialesControlComponent } from './materiales-control/materiales-control.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { StateFormModule } from 'src/app/common/components/state-form/state-form.module';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { LoadingSpinnerModule } from 'src/app/common/components/loading-spinner/loading-spinner.module';
import { MaterialesRoutingModule } from './materiales-routing.module';
import { MaterialesRecepcionComponent } from './materiales-recepcion/materiales-recepcion.component';
import { TuboComponent } from './materiales-recepcion/subforms/tubo/tubo.component';
import { CamFieldModule } from 'src/app/common/components/cam-field/cam-field.module';
import { MarcoComponent } from './materiales-recepcion/subforms/marco/marco.component';
import { JuntaComponent } from './materiales-recepcion/subforms/junta/junta.component';
import { CodoComponent } from './materiales-recepcion/subforms/codo/codo.component';
import { ShowErrorsDirective } from 'src/app/common/directives/show-errors.directive';

@NgModule({
  declarations: [
    MaterialesControlComponent,
    MaterialesRecepcionComponent,
    TuboComponent,
    MarcoComponent,
    JuntaComponent,
    CodoComponent,
  ],
  imports: [
    CamFieldModule,
    CommonModule,
    FormsModule,
    LoadingSpinnerModule,
    MaterialesRoutingModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    StateFormModule,
    ShowErrorsDirective,
  ],
})
export class MaterialesModule {}
