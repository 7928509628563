<header>
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <mat-icon (click)="drawer.toggle()" class="navbar_mobile">menu</mat-icon>
      <a routerLink="/home" routerLinkActive="router-link-active"
        ><img src="assets/images/logoGTC_white.png" alt="Logo" />
      </a>
      <div class="navbar">
        <!-- <button mat-button [matMenuTriggerFor]="menuCombustible">
          Combustible
        </button>
        <button mat-button [matMenuTriggerFor]="menuEncuestas">
          Encuestas
        </button>
        <button mat-button [matMenuTriggerFor]="menuEquipos">
          Equipos/Vehiculos
        </button>
        <button mat-button [matMenuTriggerFor]="menuSGD">SGD</button> -->
        @if (isLogged) {
        <button mat-button [matMenuTriggerFor]="menuMateriales">
          Materiales
        </button>
        }
      </div>
      <div class="end">
        @if (isLogged) {
        <span class="nameTag">{{ name }}</span>
        |
        <button mat-button (click)="logout()">Cerrar sesión</button>
        } @else if (!isLogged) {
        <button mat-button (click)="login()">Iniciar sesión</button>
        }
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</header>
<mat-drawer-container [hasBackdrop]="true">
  <mat-drawer #drawer mode="side">
    <ul>
      <!-- <li>
        <button mat-button [matMenuTriggerFor]="menuCombustible">
          Combustible
        </button>
      </li>
      <li>
        <button mat-button [matMenuTriggerFor]="menuEncuestas">
          Encuestas
        </button>
      </li>
      <li>
        <button mat-button [matMenuTriggerFor]="menuEquipos">
          Equipos/Vehiculos
        </button>
      </li>
      <li>
        <button mat-button [matMenuTriggerFor]="menuSGD">SGD</button>
      </li> -->
      @if (isLogged) {
      <li>
        <button mat-button [matMenuTriggerFor]="menuMateriales">
          Materiales
        </button>
      </li>
      }
    </ul>
  </mat-drawer>
  <mat-drawer-content>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<!-- Creacion de estructura de los menus -->

<!-- <mat-menu #menuCombustible="matMenu">
  <button mat-menu-item routerLink="forms/registro-combustible">
    Combustible
  </button>
  <button mat-menu-item>Combustible carrotanque</button>
  <button mat-menu-item>Combustible carrotanque - ODS003</button>
</mat-menu>

<mat-menu #menuEncuestas="matMenu">
  <button mat-menu-item routerLink="forms/perfil-sociodemografico">
    Perfil sociodemográfico
  </button>
  <button mat-menu-item routerLink="forms/prueba-alcoholemia">
    Pruebas de alcoholemia
  </button>
  <button mat-menu-item routerLink="forms/reporte-condiciones-inseguras">
    Reporte de actos/condiciones inseguras
  </button>
  <button mat-menu-item routerLink="forms/evaluaciones">Evaluaciones</button>
</mat-menu> -->

<!-- <mat-menu #menuEquipos="matMenu">
  <button mat-menu-item routerLink="forms/diagnostico-inicial">
    DIAGNOSTICO INICIAL EQUIPO/VEHICULO
  </button>
  <button mat-menu-item routerLink="forms/hdv-vehiculo">
    HOJA DE VIDA EQUIPOS/VEHICULOS
  </button>
  <button mat-menu-item>Mantenimiento Equipo/Vehiculo</button>
  <button mat-menu-item routerLink="forms/preoperacional">
    PREOPERACIONAL EQUIPOS/VEHICULOS
  </button>
</mat-menu> -->

<!-- <mat-menu #menuSGD="matMenu">
  <button mat-menu-item routerLink="forms/entrega">Entrega</button>
  <button mat-menu-item routerLink="forms/hoja-de-vida">Hoja de vida</button>
</mat-menu> -->

<mat-menu #menuMateriales="matMenu">
  <button mat-menu-item routerLink="materiales/recepcion">Recepción</button>
  <button mat-menu-item routerLink="materiales/control">Control</button>
</mat-menu>
