import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateFormComponent } from './state-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { WebcamModule } from 'ngx-webcam';
import { MatInputModule } from '@angular/material/input';
import { CamFieldModule } from '../cam-field/cam-field.module';
import { InFormEstadoComponent } from './subforms/in-form-estado/in-form-estado.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [StateFormComponent, InFormEstadoComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    CamFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    WebcamModule,
  ],
  exports: [StateFormComponent],
})
export class StateFormModule {}
