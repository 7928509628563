<div [formGroup]="marcoForm" class="grid">
  <div class="field col-12 sm:col-6">
    <b>Material</b>
    <mat-form-field>
      <mat-label>Material</mat-label>
      <mat-select formControlName="material">
        <mat-option *ngFor="let material of materiales" [value]="material">
          {{ material }}
        </mat-option>
      </mat-select>
      <mat-error [fieldShowErrors]="marcoForm.get('material')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Posicion del marco</b>
    <mat-form-field>
      <mat-label>Posicion</mat-label>
      <input
        matInput
        formControlName="posicion"
        type="number"
        class="no-arrows"
      />
      <mat-error [fieldShowErrors]="marcoForm.get('posicion')!"></mat-error>
    </mat-form-field>
  </div>
</div>
