import { Injectable, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class MaterialesRecepcionService {
  private fb = inject(FormBuilder);
  public receptionForm: FormGroup;

  constructor() {
    this.receptionForm = this.fb.group({
      tipoElemento: ['', Validators.required],
      proyecto: ['', Validators.required],
      observaciones: [''],
    });
  }
}
