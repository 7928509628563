import { Component, OnDestroy, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-cam-field',
  templateUrl: './cam-field.component.html',
  styleUrls: ['./cam-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CamFieldComponent),
      multi: true,
    },
  ],
})
export class CamFieldComponent implements ControlValueAccessor, OnDestroy {
  public showWebcam = false;
  public webcamImage: WebcamImage | null = null;
  private trigger: Subject<void> = new Subject<void>();
  public value = new FormControl('');
  subscriptions: Subscription[] = [];

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get hasValue(): boolean {
    return this.value.value ? true : false;
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.value.setValue(webcamImage.imageAsDataUrl);
    this.showWebcam = false;
  }

  public showCam(): void {
    this.showWebcam = true;
  }

  public retryHandler(): void {
    this.webcamImage = null;
    this.value.setValue(null);
    this.showWebcam = true;
  }

  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.subscriptions.push(this.value.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.value.setValue(value);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
