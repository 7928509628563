<div [formGroup]="junta" class="grid">
  <div class="field col-12 sm:col-6">
    <b>Kilometro</b>
    <mat-form-field>
      <mat-label>Kilometro</mat-label>
      <input
        matInput
        formControlName="kilometro"
        type="number"
        class="no-arrows"
      />
      <mat-error [fieldShowErrors]="junta.get('kilometro')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Número de junta</b>
    <mat-form-field>
      <mat-label>Número</mat-label>
      <input
        matInput
        formControlName="numero"
        type="number"
        class="no-arrows"
      />
      <mat-error [fieldShowErrors]="junta.get('numero')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Tubo 1</b>
    <mat-form-field>
      <mat-label>Tubo 1</mat-label>
      <mat-select formControlName="tubo1">
        <mat-option *ngFor="let tubo of tubos" [value]="tubo">{{
          tubo.nombre ?? "Consecutivo " + tubo.idMaterial
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Tubo 2</b>
    <mat-form-field>
      <mat-label>Tubo 2</mat-label>
      <mat-select formControlName="tubo2">
        <mat-option *ngFor="let tubo of tubos" [value]="tubo">{{
          tubo.nombre ?? "Consecutivo " + tubo.idMaterial
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Pulgadas</b>
    <mat-form-field>
      <mat-label>Pulgadas</mat-label>
      <input
        matInput
        formControlName="pulgadas"
        type="number"
        class="no-arrows"
      />
      <span matSuffix class="mx-2">in</span>
      <mat-error [fieldShowErrors]="junta.get('pulgadas')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Foto de la junta antes de pintar</b>
    <app-cam-field formControlName="fotoJunta"></app-cam-field>
    <mat-error [fieldShowErrors]="junta.get('fotoJunta')!"></mat-error>
  </div>
</div>
