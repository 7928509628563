import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MaterialesRecepcionService } from '../../materiales-recepcion.service';
import { ShowErrorsDirective } from 'src/app/common/directives/show-errors.directive';

@Component({
  selector: 'recepcion-marco',
  templateUrl: './marco.component.html',
  styles: ``,
})
export class MarcoComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private recepcionService = inject(MaterialesRecepcionService);

  public materiales = [
    'API5LX42',
    'API5LX53',
    'API5LX60',
    'API5LX70',
    'ASTM A106 Gr B',
    'ASTM A53 Gr B',
  ];

  @ViewChildren(ShowErrorsDirective)
  showErrorsDirectives!: QueryList<ShowErrorsDirective>;

  @Input() submitEvent!: EventEmitter<void>;

  ngOnInit(): void {
    this.submitEvent.subscribe(() => {
      this.marcoForm.markAllAsTouched();
      this.showErrorsDirectives.forEach((dir) => dir.showErrors());
    });

    this.recepcionService.receptionForm.addControl(
      'marco',
      this.fb.group({
        material: ['', Validators.required],
        posicion: ['', [Validators.required, Validators.min(1)]],
      })
    );
  }

  ngOnDestroy(): void {
    this.recepcionService.receptionForm.removeControl('marco');
  }

  get marcoForm() {
    return this.recepcionService.receptionForm.get('marco') as FormGroup;
  }
}
