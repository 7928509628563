import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import {
  Proyecto,
  TipoMaterial,
} from '../../../common/interfaces/materiales.interfaces';
import { environment } from '../../../../environments/environment';
import { MaterialesRecepcionService } from './materiales-recepcion.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/common/components/dialog/dialog.component';
import { ShowErrorsDirective } from 'src/app/common/directives/show-errors.directive';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  templateUrl: './materiales-recepcion.component.html',
  styleUrl: './materiales-recepcion.component.scss',
})
export class MaterialesRecepcionComponent implements OnInit {
  private http = inject(HttpClient);
  private recepcionService = inject(MaterialesRecepcionService);
  private dialog = inject(MatDialog);

  public tiposElemento: TipoMaterial[] = [];
  public proyectos: Proyecto[] = [];

  @ViewChildren(ShowErrorsDirective)
  showErrorsDirectives!: QueryList<ShowErrorsDirective>;

  @Output() submitEvent = new EventEmitter<void>();

  ngOnInit(): void {
    this.http
      .get<any>(environment.materialesCRUD + 'tipo-material')
      .subscribe((response) => {
        this.tiposElemento = response.data as TipoMaterial[];
      });

    this.http
      .get<any>(environment.materialesCRUD + 'proyecto')
      .subscribe((response) => {
        this.proyectos = response.data as Proyecto[];
      });
  }

  get tipoElemento() {
    return this.recepcionService.receptionForm.get('tipoElemento')
      ?.value as TipoMaterial;
  }

  get receptionForm() {
    return this.recepcionService.receptionForm;
  }

  private base64ToBlob(base64: string): Blob {
    const parts = base64.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  // Función para preparar FormData
  private prepareFormData(
    formGroup: FormGroup,
    formData: FormData = new FormData(),
    parentKey = ''
  ): void {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control = formGroup.controls[key];
        const fullKey = parentKey ? `${parentKey}.${key}` : key;

        if (control instanceof FormGroup) {
          this.prepareFormData(control, formData, fullKey);
        } else if (control instanceof FormArray) {
          control.controls.forEach((group, index) => {
            if (group instanceof FormGroup) {
              this.prepareFormData(group, formData, `${fullKey}[${index}]`);
            }
          });
        } else {
          let value = control.value;

          if (typeof value === 'string' && value.startsWith('data:image')) {
            value = this.base64ToBlob(value);
          } else if (typeof value === 'object' && value !== null) {
            value = JSON.stringify(value);
          }

          formData.append(fullKey, value);
        }
      }
    }
  }

  public submitHandler() {
    this.submitEvent.emit();
    if (this.receptionForm.invalid) {
      this.showErrorsDirectives.forEach((dir) => dir.showErrors());
      return;
    }
    const formData = new FormData();
    this.prepareFormData(this.receptionForm, formData);
    this.http
      .post<any>(
        environment.formsMID +
          `materiales/${this.receptionForm.value.tipoElemento.nombre.toLowerCase()}`,
        formData
      )
      .subscribe((response) => {
        this.dialog.open(DialogComponent, {
          data: {
            title: 'Material creado',
            content: !(response instanceof Array)
              ? 'Consecutivo del material: ' +
                response.materiales.data.idMaterial
              : 'Consecutivos de los materiales: ' +
                response
                  .map((material: any) => material.materiales.data.idMaterial)
                  .join(', '),
          },
        });
      });
  }
}
