import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  loginForm: FormGroup;
  hide: boolean = true;

  constructor(private readonly fb: FormBuilder) {
    this.loginForm = this.fb.group({
      username: '',
      password: '',
    });
  }
}
