import { Component } from '@angular/core';
import { MaterialsControlService } from './materials-control.service';
import { Modelo } from 'src/app/common/interfaces/materiales.interfaces';

@Component({
  templateUrl: './materiales-control.component.html',
  styleUrls: ['./materiales-control.component.scss'],
})
export class MaterialesControlComponent {
  constructor(private materialsService: MaterialsControlService) {}

  modelChangeHandler(modelo: Modelo) {
    return this.materialsService.modelChangeHandler(modelo);
  }

  get selectedModel() {
    return this.materialsService.selectedModel;
  }

  get modelosList() {
    return this.materialsService.modelosList;
  }

  get updateMaterialsForm() {
    return this.materialsService.updateMaterialsForm;
  }

  onSubmit() {
    this.materialsService.submitHandler();
  }

  get estados() {
    return this.materialsService.estados;
  }

  get isLoading() {
    return this.materialsService.isLoading;
  }

  addEstado() {
    this.estados.push(this.materialsService.newEstado());
  }
}
