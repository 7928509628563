import { Component, inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  private keycloak = inject(KeycloakService);

  public isLogged = false;
  public name = '';

  constructor() {
    if (this.keycloak.isLoggedIn()) {
      this.isLogged = true;
      this.keycloak.loadUserProfile().then((profile) => {
        this.name = profile.firstName! + ' ' + profile.lastName!;
      });
    }
  }

  public esVistaMovil(): boolean {
    return window.innerWidth < 950;
  }

  public login(): void {
    this.keycloak.login();
  }

  public logout(): void {
    this.keycloak.logout();
  }
}
