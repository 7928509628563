import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  inject,
} from '@angular/core';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { Material } from 'src/app/common/interfaces/materiales.interfaces';
import { keysToLower } from 'src/app/common/utils/utilities';
import { MaterialsControlService } from 'src/app/pages/materiales/materiales-control/materials-control.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'inForm-estado',
  templateUrl: './in-form-estado.component.html',
  styles: ``,
})
export class InFormEstadoComponent implements OnChanges, OnDestroy {
  @Input() index: number = 0;
  @Input() estado: string = '';

  private fb = inject(FormBuilder);
  private materialsService = inject(MaterialsControlService);
  private http = inject(HttpClient);

  public contratistasList: string[] = [
    'CONSORCIO YHD INGENIERIA Y CONSTRUCCIONES',
    'ALLPRO SERVICE DEL LLANO SAS',
  ];

  private formDict: any = {
    tubo: {
      'sandblasting/pintura': this.fb.group({
        temperatura: [''],
        fotoTemperatura: [''],
        rugosimetro: [''],
        fotoRugosimetro: [''],
        espesores: [''],
        fotoEspesores: [''],
        fotoPruebaSandblasting: [''],
        fotoPruebaPintura: [''],
        fotoEspesorPeliculaHumeda: [''],
        fotoEspesorPeliculaSeca: [''],
        contratista: [''],
        aprobacion: [false],
        razon: [''],
      }),
      doblado: this.fb.group({
        posicion: [null],
        grados: [null],
      }),
      tendido: this.fb.group({
        posicion: [null],
      }),
    },
    marco: {
      hincado: this.fb.group({
        fotoSoporte: [''],
        subPiloteIzquierdo: [''],
        subPiloteDerecho: [''],
      }),
      alargado: this.fb.group({
        piloteIzquierdo: [0],
        piloteDerecho: [0],
      }),
      punteado: this.fb.group({
        fotoPunteado: [''],
      }),
      soldado: this.fb.group({
        fotoSoldado: [''],
      }),
    },
    junta: {
      pintada: this.fb.group({
        fotoPintada: [''],
      }),
    },
    codo: {},
  };

  public approveChange = () =>
    this.subForm.value.aprobacion
      ? this.subForm.get('razonRechazo')?.reset()
      : null;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['estado'].firstChange && this.estadoForm) {
      this.estadoForm.removeControl(
        changes['estado'].previousValue.toLowerCase()
      );
    }
    if (
      changes['estado'] &&
      this.formDict[
        (this.material.tipoMaterial.padre
          ? this.material.tipoMaterial.padre.nombre!
          : this.material.tipoMaterial.nombre!
        ).toLowerCase()
      ][changes['estado'].currentValue.toLowerCase()]
    ) {
      this.estadoForm.addControl(
        changes['estado'].currentValue.toLowerCase(),
        this.formDict[
          (this.material.tipoMaterial.padre
            ? this.material.tipoMaterial.padre.nombre!
            : this.material.tipoMaterial.nombre!
          ).toLowerCase()
        ][changes['estado'].currentValue.toLowerCase()]
      );
      this.http
        .get(
          environment.formsCRUD +
            `states-trace/${this.material.modelo.idModelo}/${this.material.idMaterial}`
        )
        .subscribe((res: any) => {
          const statesRegs = keysToLower(res.data.registroEstados);
          this.subForm.patchValue({
            ...res.data,
            ...statesRegs[changes['estado'].currentValue.toLowerCase()],
          });
        });
    }
  }

  ngOnDestroy(): void {
    this.estadoForm.removeControl(this.estado.toLowerCase());
  }

  public get estadoForm() {
    return (this.materialsService.estados as FormArray).at(
      this.index
    ) as FormGroup;
  }

  public get subForm() {
    return this.estadoForm.get(this.estado.toLowerCase()) as FormGroup;
  }

  public get material() {
    return this.estadoForm.get('material')?.value as Material;
  }
}
