import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MaterialesRecepcionService } from '../../materiales-recepcion.service';
import { ShowErrorsDirective } from 'src/app/common/directives/show-errors.directive';

@Component({
  selector: 'recepcion-codo',
  templateUrl: './codo.component.html',
  styles: ``,
})
export class CodoComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private materialesRecepcionService = inject(MaterialesRecepcionService);

  public tipos = [
    { value: 'semicodo', label: 'Codo 90°' },
    { value: 'codo', label: 'Codo 45°' },
  ];

  @ViewChildren(ShowErrorsDirective)
  showErrorsDirectives!: QueryList<ShowErrorsDirective>;

  @Input() submitEvent!: EventEmitter<void>;

  ngOnInit(): void {
    this.submitEvent.subscribe(() => {
      this.codo.markAllAsTouched();
      this.showErrorsDirectives.forEach((dir) => dir.showErrors());
    });

    this.materialesRecepcionService.receptionForm.addControl(
      'codo',
      this.fb.group({
        consecutivo: ['', Validators.required],
        tipo: ['', Validators.required],
        colada: ['', Validators.required],
        fotoColada: ['', Validators.required],
      })
    );
  }

  ngOnDestroy(): void {
    this.materialesRecepcionService.receptionForm.removeControl('codo');
  }

  get codo() {
    return this.materialesRecepcionService.receptionForm.get(
      'codo'
    ) as FormGroup;
  }
}
