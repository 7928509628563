@if (subForm) {
<div class="grid" [formGroup]="subForm">
  @switch ((this.material.tipoMaterial.padre ?
  this.material.tipoMaterial.padre.nombre! :
  this.material.tipoMaterial.nombre!).toLowerCase()) {
  <!-- Tuberia -->
  @case ('tubo') { @switch (estado) {
  <!-- Sandblasting / Pintura -->
  @case ('sandblasting/pintura') {
  <b>Contratista</b>
  <mat-form-field>
    <mat-label>Contratista</mat-label>
    <mat-select formControlName="contratista">
      <mat-option
        *ngFor="let contratista of contratistasList"
        [value]="contratista"
      >
        {{ contratista }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="field col-12 sm:col-6">
    <b>Temperatura ambiente</b>
    <mat-form-field>
      <mat-label>Temperatura</mat-label>
      <input
        matInput
        class="no-arrows"
        type="number"
        formControlName="temperatura"
      />
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Foto de temperatura ambiente</b>
    <app-cam-field formControlName="fotoTemperatura"></app-cam-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Rugosimetro</b>
    <mat-form-field>
      <mat-label>Valor</mat-label>
      <input
        matInput
        class="no-arrows"
        type="number"
        formControlName="rugosimetro"
      />
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Foto de rugosimetro</b>
    <app-cam-field formControlName="fotoRugosimetro"></app-cam-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Medicion de espesores</b>
    <mat-form-field>
      <mat-label>Valor</mat-label>
      <input
        matInput
        class="no-arrows"
        type="number"
        formControlName="espesores"
      />
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Foto de medición de espesores</b>
    <app-cam-field formControlName="fotoEspesores"></app-cam-field>
  </div>

  <div class="field col-12 sm:col-6">
    <mat-slide-toggle formControlName="aprobacion" (toggleChange)="approveChange()"><b>Aprobación</b></mat-slide-toggle>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Razón</b>
    <mat-form-field>
      <mat-label>Razón</mat-label>
      <textarea matInput formControlName="razon"></textarea>
    </mat-form-field>
  </div>

  <h2 class="col-12">Fotos de prueba de ejecución</h2>

  <div class="field col-12">
    <b>Foto de la persona haciendo el sandblasting</b>
    <app-cam-field formControlName="fotoPruebaSandblasting"></app-cam-field>
  </div>

  <div class="field col-12">
    <b>Foto de la persona pintando</b>
    <app-cam-field formControlName="fotoPruebaPintura"></app-cam-field>
  </div>

  <div class="field col-12">
    <b>Foto de prueba de espesor en película húmeda</b>
    <app-cam-field formControlName="fotoEspesorPeliculaHumeda"></app-cam-field>
  </div>

  <div class="field col-12">
    <b>Foto de prueba de espesor en película seca</b>
    <app-cam-field formControlName="fotoEspesorPeliculaSeca"></app-cam-field>
  </div>
  }

  <!-- Doblado -->
  @case ('doblado') {
  <div class="field col-12">
    <b>Posicion</b>
    <mat-form-field>
      <mat-label>Posicion</mat-label>
      <input
        matInput
        formControlName="posicion"
        type="number"
        class="no-arrows"
      />
    </mat-form-field>
  </div>

  <div class="field col-12">
    <b>Grados</b>
    <mat-form-field>
      <mat-label>Grados</mat-label>
      <input
        matInput
        formControlName="grados"
        type="number"
        class="no-arrows"
      />
    </mat-form-field>
  </div>
  }

  <!-- Tendido -->
  @case ('tendido') {
  <div class="field col-12">
    <b>Posicion</b>
    <mat-form-field>
      <mat-label>Posicion</mat-label>
      <input
        matInput
        formControlName="posicion"
        type="number"
        class="no-arrows"
      />
    </mat-form-field>
  </div>
  } } } @case ('marco') { @switch (estado) {
  <!-- Hincado -->
  @case ('hincado') {
  <div class="field col-12 sm:col-6">
    <b>Longitud de hincado de pilote izquierdo</b>
    <mat-form-field>
      <mat-label>Medida</mat-label>
      <input
        matInput
        formControlName="subPiloteIzquierdo"
        type="number"
        class="no-arrows"
      />
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Longitud de hincado de pilote derecho</b>
    <mat-form-field>
      <mat-label>Medida</mat-label>
      <input
        matInput
        formControlName="subPiloteDerecho"
        type="number"
        class="no-arrows"
      />
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Foto de soporte</b>
    <app-cam-field formControlName="fotoSoporte"></app-cam-field>
  </div>
  }

  <!-- Alargado -->
  @case ('alargado') {
  <div class="field col-12 sm:col-6">
    <b>Longitud de alargue de pilote izquierdo</b>
    <mat-form-field>
      <mat-label>Medida</mat-label>
      <input
        matInput
        formControlName="piloteIzquierdo"
        type="number"
        class="no-arrows"
      />
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Longitud de alargue de pilote derecho</b>
    <mat-form-field>
      <mat-label>Medida</mat-label>
      <input
        matInput
        formControlName="piloteDerecho"
        type="number"
        class="no-arrows"
      />
    </mat-form-field>
  </div>
  }

  <!-- Punteado -->
  @case ('punteado') {
  <div class="field col-12 sm:col-6">
    <b>Foto de soporte</b>
    <app-cam-field formControlName="fotoPunteado"></app-cam-field>
  </div>
  }

  <!-- Soldado -->
  @case ('soldado') {
  <div class="field col-12 sm:col-6">
    <b>Foto de soporte</b>
    <app-cam-field formControlName="fotoSoldado"></app-cam-field>
  </div>
  } } } }
</div>
}
