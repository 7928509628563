<div class="camfield">
  <button
    mat-raised-button
    *ngIf="!showWebcam && !hasValue"
    color="primary"
    type="button"
    (click)="showCam()"
    class="camfield-openButton"
  >
    Abrir camara
  </button>

  <webcam
    *ngIf="showWebcam"
    (imageCapture)="handleImage($event)"
    [trigger]="triggerObservable"
    [height]="300"
    [width]="300"
  ></webcam>
  <img *ngIf="webcamImage || hasValue" [src]="value.value" alt="Imagen" />
  <button
    mat-fab
    color="primary"
    (click)="triggerSnapshot()"
    *ngIf="showWebcam"
    type="button"
  >
    <mat-icon> radio_button_checked</mat-icon>
  </button>
  <button
    mat-fab
    color="primary"
    (click)="retryHandler()"
    *ngIf="!showWebcam && hasValue"
    type="button"
  >
    <mat-icon> autorenew</mat-icon>
  </button>
</div>
