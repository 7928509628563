import { Directive, Input, ElementRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[fieldShowErrors]',
  standalone: true,
})
export class ShowErrorsDirective {
  @Input('fieldShowErrors') control!: AbstractControl;

  constructor(private el: ElementRef) {}

  showErrors() {
    const label = this.el.nativeElement;
    if (this.control && this.control.errors) {
      const firstKey = Object.keys(this.control.errors)[0];
      switch (firstKey) {
        case 'required':
          label.textContent = 'Este campo es requerido';
          break;

        default:
          label.textContent = 'El valor del campo invalido';
          break;
      }
    } else {
      label.textContent = '';
    }
  }
}
