<div class="login" [formGroup]="loginForm">
  <img src="assets/images/logoGTC.png" alt="Logo" />
  <h1>Iniciar sesión</h1>
  <mat-form-field>
    <mat-label>Usuario</mat-label>
    <input matInput formControlName="username" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Contraseña</mat-label>
    <input
      matInput
      formControlName="password"
      [type]="hide ? 'password' : 'text'"
    /><button
      mat-icon-button
      matSuffix
      (click)="hide = !hide"
      [attr.aria-label]="'Toggle password visibility'"
      [attr.aria-pressed]="hide"
    >
      <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
    </button>
  </mat-form-field>
  <button mat-flat-button color="primary">Entrar</button>
</div>
