<form [formGroup]="updateMaterialsForm" (ngSubmit)="onSubmit()">
  <h1>Control de materiales</h1>
  <b>Seleccione el modelo</b>
  <mat-form-field>
    <mat-label>Modelo</mat-label>
    <mat-select
      (selectionChange)="modelChangeHandler($event.value)"
      formControlName="modelo"
    >
      <mat-option *ngFor="let model of modelosList" [value]="model">
        {{ model.nombre }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-divider></mat-divider>

  @if(selectedModel.idModelo){
  <div formArrayName="estados">
    <div *ngFor="let estado of estados.controls; let i = index">
      <app-state-form [index]="i"></app-state-form>
    </div>
  </div>

  <button mat-fab color="primary" type="button" (click)="addEstado()">
    <mat-icon>add</mat-icon>
  </button>
  <button mat-fab color="primary" aria-label="Submit data" type="submit">
    <mat-icon>save</mat-icon></button
  >}
</form>
<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
