export const vehiculosList = [
  {
    name: 'Bomba',
    value: 'bomba',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Bulldozer',
    value: 'bulldozer',
    items: ['luces', 'cabina', 'estadoMecanicoPesado'],
  },
  {
    name: 'Bus',
    value: 'bus',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Cama alta',
    value: 'camaAlta',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Cama Baja',
    value: 'camaBaja',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Camion',
    value: 'camion',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Camioneta',
    value: 'camioneta',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Camion grua',
    value: 'camionGrua',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Camion de vacio',
    value: 'camionVacio',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Cargador frontal',
    value: 'cargadorFrontal',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Carrotanque Agua',
    value: 'carrotanqueAgua',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Carrotanque combustible',
    value: 'carrotanqueCombustible',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Compresor',
    value: 'compresor',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Dobladora',
    value: 'dobladora',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Estacion',
    value: 'estacion',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Grua',
    value: 'grua',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Maquina de coser',
    value: 'maquinaCoser',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Mezcladora',
    value: 'mezcladora',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Mini-cargador',
    value: 'miniCargador',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Mini-excavadora',
    value: 'miniExcavadora',
    items: ['orugas', 'luces', 'cabina', 'estadoMecanicoPesado', 'documentos'],
  },
  {
    name: 'Motoniveladora',
    value: 'motoniveladora',
    items: ['luces', 'cabina', 'estadoMecanicoPesado', 'llantas'],
  },
  {
    name: 'Motosoldador',
    value: 'motosoldador',
    items: [
      'carcaza',
      'estadoElectrico',
      'estadoMecanico',
      'prevencion',
      'puestoTrabajo',
    ],
  },
  {
    name: 'Mula grua',
    value: 'mulaGrua',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Nivel',
    value: 'nivel',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Perforadora',
    value: 'perforadora',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  { name: 'Planta electrica', value: 'plantaElectrica', items: ['planta'] },
  { name: 'Planta estadio', value: 'plantaEstadio' },
  {
    name: 'Prestamo',
    value: 'prestamo',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Recicladora',
    value: 'recicladora',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'RetroExcavadora',
    value: 'retroexcavadora',
    items: ['orugas', 'luces', 'cabina', 'estadoMecanicoPesado', 'documentos'],
  },
  {
    name: 'RTK',
    value: 'RTK',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Scanner',
    value: 'scanner',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Tractor',
    value: 'tractor',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Turbo',
    value: 'turbo',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Vacuum',
    value: 'vacuum',
    items: ['carcaza', 'vacuum', 'estadoElectrico', 'luces', 'documentos'],
  },
  {
    name: 'Vibrocompactador',
    value: 'vibrocompactador',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
  {
    name: 'Volqueta',
    value: 'volqueta',
    items: [
      'luces',
      'sistemaAdvertencia',
      'estadoMecanico',
      'llantas',
      'prevencion',
      'documentos',
      'caducidad',
    ],
  },
];

export const arbolContratos = [
  {
    nombre: 'CONTRATO 8000007572',
    empresa: 'CENIT',
    proyectos: [
      {
        id: 197,
        nombre: 'CENIT.MEC.8-7572 ODS-001 GUADUERO-TRAMPAS',
      },
    ],
  },
];

export function keysToLower(obj: any): any {
  const lowerCaseObj: any = {};
  for (const key of Object.keys(obj)) {
    lowerCaseObj[key.toLowerCase()] = obj[key];
  }
  return lowerCaseObj;
}
