import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { MaterialesRecepcionService } from '../../materiales-recepcion.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShowErrorsDirective } from 'src/app/common/directives/show-errors.directive';
import { Material } from 'src/app/common/interfaces/materiales.interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'recepcion-junta',
  templateUrl: './junta.component.html',
  styles: ``,
})
export class JuntaComponent implements OnInit, OnDestroy {
  private materialesRecepcionService = inject(MaterialesRecepcionService);
  private fb = inject(FormBuilder);
  private http = inject(HttpClient);

  public tubos: Material[] = [];

  @ViewChildren(ShowErrorsDirective)
  showErrorsDirectives!: QueryList<ShowErrorsDirective>;

  @Input() submitEvent!: EventEmitter<void>;

  ngOnInit(): void {
    this.submitEvent.subscribe(() => {
      this.junta.markAllAsTouched();
      this.showErrorsDirectives.forEach((dir) => dir.showErrors());
    });

    this.http
      .get<any>(
        environment.materialesCRUD + 'material?tipoMaterial.idTipoMaterial=1'
      )
      .subscribe((response) => {
        this.tubos = response.data as Material[];
      });

    this.materialesRecepcionService.receptionForm.addControl(
      'junta',
      this.fb.group({
        kilometro: ['', Validators.required],
        numero: ['', [Validators.required, Validators.min(1)]],
        pulgadas: ['', [Validators.required, Validators.min(1)]],
        tubo1: ['', Validators.required],
        tubo2: ['', Validators.required],
        fotoJunta: ['', Validators.required],
      })
    );
  }

  ngOnDestroy(): void {
    this.materialesRecepcionService.receptionForm.removeControl('junta');
  }

  get junta() {
    return this.materialesRecepcionService.receptionForm.get(
      'junta'
    ) as FormGroup;
  }
}
