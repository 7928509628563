import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MaterialesControlComponent } from './materiales-control/materiales-control.component';
import { MaterialesRecepcionComponent } from './materiales-recepcion/materiales-recepcion.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'control',
        component: MaterialesControlComponent,
      },
      {
        path: 'recepcion',
        component: MaterialesRecepcionComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MaterialesRoutingModule {}
