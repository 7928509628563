import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MaterialsControlService } from '../../../pages/materiales/materiales-control/materials-control.service';
import { environment } from 'src/environments/environment';
import {
  Estado,
  Material,
  TipoMaterial,
} from '../../interfaces/materiales.interfaces';
import { FormArray, FormGroup } from '@angular/forms';
import { Response } from 'src/app/common/interfaces/response.interface';

@Component({
  selector: 'app-state-form',
  templateUrl: './state-form.component.html',
  styleUrls: ['./state-form.component.scss'],
})
export class StateFormComponent {
  selectedState?: Estado;
  selectedTipo?: TipoMaterial;
  estadosList: Estado[] = [];
  materialesList: Material[] = [];
  @Input() index: number = 0;

  constructor(
    private materialsService: MaterialsControlService,
    private http: HttpClient
  ) {}

  public async setEstado(material: Material) {
    this.http
      .get<Estado[]>(
        `${environment.materialesCRUD}estado/tipoMaterial/${material?.tipoMaterial.idTipoMaterial}`
      )
      .subscribe((data: any) => {
        this.estadosList = data['data'];
        const estado = this.estadosList.find(
          (estado) => estado.idEstado === material?.estado.idEstado
        );
        this.selectedState = estado;
        this.estadoForm.patchValue({ estado });
      });
  }

  public removeHandler() {
    this.materialsService.removeEstado(this.index);
  }

  public onTypeChange() {
    this.http
      .get(
        environment.materialesCRUD +
          `material?idModelo=${
            this.materialsService.selectedModel.idModelo
          }&idTipoMaterial=${this.selectedTipo!.idTipoMaterial}`
      )
      .subscribe((data) => {
        const responseData = data as Response;
        if (responseData?.statusCode == 200) {
          this.materialesList = responseData.data as Material[];
        }
      });
  }

  public get tiposElemento(): TipoMaterial[] {
    return this.materialsService.tiposElemento;
  }

  public get estados() {
    return this.materialsService.estados;
  }

  public get selectedEstado() {
    return this.estadoForm.value.estado as Estado;
  }

  public get selectedMaterial() {
    return this.estadoForm.value.material as Material;
  }

  public get estadoForm() {
    return (this.materialsService.estados as FormArray).at(
      this.index
    ) as FormGroup;
  }
}
