<h1>Registro de recepción de materiales</h1>
<form
  [formGroup]="receptionForm"
  (ngSubmit)="submitHandler()"
  class="grid formgrid"
>
  <div class="field col-12 sm:col-6">
    <b>Tipo de elemento</b>
    <mat-form-field>
      <mat-label>Tipo elemento</mat-label>
      <mat-select formControlName="tipoElemento">
        <mat-option *ngFor="let tipo of tiposElemento" [value]="tipo">
          {{ tipo.nombre }}
        </mat-option>
      </mat-select>
      <mat-error
        [fieldShowErrors]="receptionForm.get('tipoElemento')!"
      ></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Proyecto</b>
    <mat-form-field>
      <mat-label>Proyecto</mat-label>
      <mat-select formControlName="proyecto">
        <mat-option *ngFor="let proyecto of proyectos" [value]="proyecto">{{
          proyecto.nombre
        }}</mat-option>
      </mat-select>
      <mat-error [fieldShowErrors]="receptionForm.get('proyecto')!"></mat-error>
    </mat-form-field>
  </div>

  @switch (tipoElemento.nombre?.toLowerCase()) { @case ('tubo') {
  <recepcion-tuberia [submitEvent]="submitEvent" class="col-12" />
  } @case ('marco') {
  <recepcion-marco [submitEvent]="submitEvent" class="col-12" />
  } @case ('junta') {
  <recepcion-junta [submitEvent]="submitEvent" class="col-12" />
  } @case ('codo') {
  <recepcion-codo [submitEvent]="submitEvent" class="col-12" />
  } }

  <div class="field col-12">
    <b>Observaciones</b>
    <mat-form-field>
      <mat-label>Observaciones</mat-label>
      <textarea matInput formControlName="observaciones"></textarea>
    </mat-form-field>
  </div>

  <button
    mat-fab
    color="primary"
    type="submit"
    aria-label="Guardar datos"
    class="col-12 mx-auto"
  >
    <mat-icon>save</mat-icon>
  </button>
</form>
