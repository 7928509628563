import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MaterialesRecepcionService } from '../../materiales-recepcion.service';
import { ShowErrorsDirective } from 'src/app/common/directives/show-errors.directive';

@Component({
  selector: 'recepcion-tuberia',
  templateUrl: './tubo.component.html',
  styles: ``,
})
export class TuboComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private recepcionService = inject(MaterialesRecepcionService);

  public materiales = [
    'API5LX42',
    'API5LX52',
    'API5LX53',
    'API5LX60',
    'API5LX65',
    'API5LX70',
    'ASTM A106 Gr B',
    'ASTM A53 Gr B',
  ];

  public diametrosList = [
    "3/4''",
    "1''",
    "1 1/2''",
    "2''",
    "3''",
    "4''",
    "5''",
    "6''",
    "8''",
    "9''",
    "10''",
    "12''",
    "14''",
    "16''",
    "18''",
    "20''",
    "24''",
    "30''",
  ];

  public schedulesList = [
    '20',
    '30',
    '40s',
    'STD',
    '40',
    '60',
    '80s',
    'XS',
    '80',
    '100',
    '120',
    '140',
    '160',
    'XXS',
  ];

  public procedenciaList = ['Ecopetrol', 'GTC'];

  public procesos = ['SMLS', 'ERW', 'LSAW', 'SSAW'];

  public recubrimientoList = ['FBE', 'EPOXICO'];

  @ViewChildren(ShowErrorsDirective)
  showErrorsDirectives!: QueryList<ShowErrorsDirective>;

  @Input() submitEvent!: EventEmitter<void>;

  ngOnInit(): void {
    this.submitEvent.subscribe(() => {
      this.tuboForm.markAllAsTouched();
      this.showErrorsDirectives.forEach((dir) => dir.showErrors());
    });

    this.recepcionService.receptionForm.addControl(
      'tubo',
      this.fb.group({
        material: ['', Validators.required],
        consecutivoFabricante: ['', Validators.required],
        colada: ['', Validators.required],
        fotoColada: ['', Validators.required],
        fotoRemision: ['', [Validators.required]],
        longitud: ['', [Validators.required]],
        diametro: ['', [Validators.required]],
        schedule: ['', [Validators.required]],
        proceso: ['', [Validators.required]],
        procedencia: ['', [Validators.required]],
        recubrimiento: [''],
      })
    );
  }

  ngOnDestroy(): void {
    this.recepcionService.receptionForm.removeControl('tubo');
  }

  get tuboForm() {
    return this.recepcionService.receptionForm.get('tubo') as FormGroup;
  }
}
