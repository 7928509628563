<div [formGroup]="codo" class="grid">
  <div class="field col-12 sm:col-6">
    <b>Tipo de codo</b>
    <mat-form-field>
      <mat-label>Tipo</mat-label>
      <mat-select formControlName="tipo">
        <mat-option *ngFor="let tipo of tipos" [value]="tipo.value">
          {{ tipo.label }}
        </mat-option>
      </mat-select>
      <mat-error [fieldShowErrors]="codo.get('tipo')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Consecutivo</b>
    <mat-form-field>
      <mat-label>Consecutivo</mat-label>
      <input
        matInput
        formControlName="consecutivo"
        type="number"
        class="no-arrows"
      />
      <mat-error [fieldShowErrors]="codo.get('consecutivo')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Colada</b>
    <mat-form-field>
      <mat-label>Colada</mat-label>
      <input matInput formControlName="colada" />
      <mat-error [fieldShowErrors]="codo.get('colada')!"></mat-error>
    </mat-form-field>
  </div>

  <div class="field col-12 sm:col-6">
    <b>Foto del código de la colada</b>
    <app-cam-field formControlName="fotoColada"></app-cam-field>
    <mat-error [fieldShowErrors]="codo.get('fotoColada')!"></mat-error>
  </div>
</div>
