import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs';
import { DialogComponent } from 'src/app/common/components/dialog/dialog.component';
import {
  Estado,
  Material,
  Modelo,
  TipoMaterial,
} from 'src/app/common/interfaces/materiales.interfaces';
import { Response } from 'src/app/common/interfaces/response.interface';
import { environment } from 'src/environments/environment';

interface estadoForm {
  material: number;
  estado: number;
}
@Injectable({
  providedIn: 'root',
})
export class MaterialsControlService {
  modelosList: Modelo[] = [];
  selectedModel: Modelo = {} as Modelo;
  updateMaterialsForm: FormGroup;
  isLoading: boolean = true;
  tiposElemento: TipoMaterial[] = [];

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.updateMaterialsForm = this.fb.group({
      modelo: {},
      estados: this.fb.array([
        this.fb.group({
          material: {},
          estado: {},
        }),
      ]),
    });
    this.http
      .get<any>(environment.materialesCRUD + 'tipo-material')
      .subscribe((response) => {
        this.tiposElemento = response.data as TipoMaterial[];
      });
    this.http
      .get(environment.materialesCRUD + 'modelo')
      .pipe(catchError(() => [(this.isLoading = false)]))
      .subscribe((data) => {
        const responseData = data as Response;
        this.modelosList = responseData.data as Modelo[];
        this.isLoading = false;
      });
  }

  get estados(): FormArray {
    return this.updateMaterialsForm.get('estados') as FormArray;
  }

  modelChangeHandler(modelo: Modelo) {
    this.selectedModel = modelo;
  }

  newEstado(): FormGroup {
    return this.fb.group({
      material: {},
      estado: {},
    });
  }

  removeEstado(i: number) {
    this.estados.removeAt(i);
  }

  public async updateState(estado: estadoForm) {
    return new Promise((resolve, reject) => {
      this.http
        .put<any>(
          `${environment.materialesCRUD}material/${estado.material}/${this.selectedModel.idModelo}`,
          {
            estado: estado.estado,
          }
        )
        .subscribe((data) => {
          if (data.statusCode === 200) {
            resolve(data);
            return data;
          } else {
            console.error(data);
            reject(data);
            return data;
          }
        });
    });
  }

  submitHandler() {
    this.isLoading = true;
    const data = new FormData();
    this.prepareFormData(this.updateMaterialsForm, data);
    this.http
      .put<any>(environment.formsMID + 'materiales', data)
      .pipe(
        catchError((error) => {
          console.error(error);
          this.dialog.open(DialogComponent, {
            data: {
              title: 'Error',
              content: 'Ha ocurrido un error al actualizar los datos',
            },
          });
          this.isLoading = false;
          return [];
        })
      )
      .subscribe((data) => {
        this.dialog.open(DialogComponent, {
          data: {
            title: 'Datos actualizados',
            content: 'Los datos han sido actualizados',
          },
        });
        this.isLoading = false;
      });
  }

  private base64ToBlob(base64: string): Blob {
    const parts = base64.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  // Función para preparar FormData
  private prepareFormData(
    formGroup: FormGroup,
    formData: FormData = new FormData(),
    parentKey = ''
  ): void {
    // Recorremos los controles del formulario
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control = formGroup.controls[key];
        const fullKey = parentKey ? `${parentKey}.${key}` : key;

        if (control instanceof FormGroup) {
          // Si el control es un FormGroup, lo manejamos de forma recursiva
          this.prepareFormData(control, formData, fullKey);
        } else if (control instanceof FormArray) {
          // Si el control es un FormArray, lo manejamos de forma recursiva
          control.controls.forEach((group, index) => {
            if (group instanceof FormGroup) {
              this.prepareFormData(group, formData, `${fullKey}[${index}]`);
            }
          });
        } else {
          // Si el control es un FormControl
          let value = control.value;

          // Si el valor es una cadena base64, lo convertimos a Blob
          if (typeof value === 'string' && value.startsWith('data:image')) {
            value = this.base64ToBlob(value);
          } else if (typeof value === 'object' && value !== null) {
            value = JSON.stringify(value);
          }

          // Agregamos el valor al FormData
          formData.append(fullKey, value);
        }
      }
    }
  }
}
