import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CamFieldComponent } from './cam-field.component';
import { WebcamModule } from 'ngx-webcam';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CamFieldComponent],
  imports: [
    CommonModule,
    WebcamModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  exports: [CamFieldComponent],
})
export class CamFieldModule {}
