<div class="stateForm-info" [formGroup]="estadoForm">
  <div>
    <b>Tipo de material</b>
    <mat-form-field>
      <mat-label>Tipo</mat-label>
      <mat-select [(value)]="selectedTipo" (selectionChange)="onTypeChange()">
        <mat-option *ngFor="let tipo of tiposElemento" [value]="tipo">
          {{ tipo.nombre }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    @if (selectedTipo && materialesList.length >0) {
    <b>Material</b>
    <mat-form-field>
      <mat-label>Material</mat-label>
      <mat-select
        (selectionChange)="setEstado($event.value)"
        formControlName="material"
      >
        <mat-option *ngFor="let material of materialesList" [value]="material">
          {{ material.idMaterialModelo ?? "Sin enlazar" }} -
          {{ material.nombre ?? "Consecutivo " + material.idMaterial }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    } @if (estadosList.length > 0) {
    <div>
      <b>Nuevo estado</b>
      <mat-form-field>
        <mat-label>Estado</mat-label>
        <mat-select formControlName="estado">
          <mat-option *ngFor="let estado of estadosList" [value]="estado">
            {{ estado.nombre }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <inForm-estado
      [index]="index"
      [estado]="selectedEstado.nombre.toLowerCase()"
    />
    }
  </div>
  <button mat-fab color="accent" type="button" (click)="removeHandler()">
    <mat-icon>delete</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
